<template>
  <div style="padding: 20px">

    <el-date-picker
        :clearable="false"
        style="margin-left: 10px"
        v-model="choiceDate"
        type="year"
        placeholder="请选择年份"
    />
    <el-button
        type="primary"
        icon="Search"
        style="margin-left: 10px"
        @click="selectData()"
    >搜索
    </el-button>

    <!-- 年度业绩和课消目标展示区域-->
    <div class="infinite-list" v-infinite-scroll="onReachBottom">
      <div class="items">

        <!--事业部年度业绩总目标-->
        <el-divider content-position="left" >事业部年度业绩总目标</el-divider>
        <div v-for="(item,index) in tableYeJiData" :key="index">
          <div class="item">
            <div style="font-size: 14px;margin: 10px 0">{{ item.deptName }}</div>
            <div style="font-size: 14px;margin: 10px 0">年度总业绩目标：
              <span style="font-weight: bold">{{ item.basicTargetNumber }}元</span>
            </div>
            <div style="font-size: 14px;margin: 10px 0">距规划目标差额：
              <span style="font-weight: bold">{{ (item.basicTargetNumber - item.targetNumber).toFixed(2) }}元</span>
            </div>

            <!--进度条1-->
            <el-tooltip
                content="年度目标已规划金额占比"
                placement="top"
                effect="dark"
            >
              <div style="display:flex;">
                <div style="width: 90%">
                  <el-progress
                      :text-inside="true"
                      striped
                      :percentage="getPercent(item.targetNumber,item.basicTargetNumber) > 100 ? 100 :getPercent(item.targetNumber,item.basicTargetNumber)"
                      :stroke-width="28">
                    <!-- <span>进度条当中显示文本</span>-->
                  </el-progress>
                </div>
                <div style="font-size: 14px;margin-left: 10px;font-weight: bold">
                  <!-- <span>进度条右侧文本</span>-->
                </div>
              </div>
            </el-tooltip>


            <div style="font-size: 14px;margin: 10px 0">年度已完成业绩：
              <span style="font-weight: bold">{{ item.accomplishTargetNumber }}元</span>
            </div>
            <!--进度条2-->
            <el-tooltip
                content="年度业绩目标完成占比"
                placement="top"
                effect="dark"
            >
              <div style="display:flex;">
                <div style="width: 90%">
                  <el-progress
                      :text-inside="true"
                      striped
                      :percentage="getPercent(item.accomplishTargetNumber,item.basicTargetNumber) > 100 ? 100 :getPercent(item.accomplishTargetNumber,item.basicTargetNumber)"
                      :stroke-width="28"
                      status="exception"
                  >
                    <!-- <span>进度条当中显示文本</span>-->
                  </el-progress>
                </div>
                <div style="font-size: 14px;margin-left: 10px;font-weight: bold">
                  <!-- <span>进度条右侧文本</span>-->
                </div>
              </div>
            </el-tooltip>
          </div>
        </div>

        <!--  skeleton骨架等待区-->
        <div v-if="skeletonYeJi" class="items">
          <div v-for=" (item,index) in 5" :key="index" style="text-align: center" class="item">
            <el-skeleton :rows="0" animated/>
            <div style="text-align: left;margin: 10px 0">
              <el-skeleton-item variant="text" style="width: 40%;"/>
            </div>
            <el-skeleton-item variant="text" style="margin-right: 16px"/>
            <div style="display: flex;align-items: center;margin-top: 10px">
              <el-skeleton-item variant="text" style="margin-right: 16px"/>
              <el-skeleton-item variant="text" style="width: 30%"/>
            </div>
          </div>
        </div>

        <!-- 如果没有后续数据了-->
        <div v-if="noDataYeJi === true">
          <el-empty style="margin-top: 150px" v-if="yeJiTotal===0" description="未查到数据噢!！"/>
        </div>

        <!--事业部年度课消总目标-->
        <el-divider content-position="left">事业部年度课消总目标</el-divider>
        <div v-for="(item,index) in tableKeXiaoData" :key="index">
          <div class="item">
            <div style="font-size: 14px;margin: 10px 0">{{ item.deptName }}</div>
            <div style="font-size: 14px;margin: 10px 0">年度总课消目标：
              <span style="font-weight: bold">{{ item.basicTargetMoney }}元</span>
            </div>
            <div style="font-size: 14px;margin: 10px 0">距规划目标差额：
              <span style="font-weight: bold">{{ (item.basicTargetMoney - item.targetMoney).toFixed(2) }}元</span>
            </div>

            <!--进度条1-->
            <el-tooltip
                content="年度已规划课消目标金额占比"
                placement="top"
                effect="dark"
            >
              <div style="display:flex;">
                <div style="width: 90%">
                  <el-progress
                      :text-inside="true"
                      striped
                      :percentage="getPercent(item.targetMoney,item.basicTargetMoney) > 100 ? 100 :getPercent(item.targetMoney,item.basicTargetMoney)"
                      :stroke-width="28">
                    <!-- <span>进度条当中显示文本</span>-->
                  </el-progress>
                </div>
                <div style="font-size: 14px;margin-left: 10px;font-weight: bold">
                  <!-- <span>进度条右侧文本</span>-->
                </div>
              </div>
            </el-tooltip>


            <div style="font-size: 14px;margin: 10px 0">年度已完成课消金额：
              <span style="font-weight: bold">{{ item.thisMoney }}元</span>
            </div>
            <!--进度条2-->
            <el-tooltip
                content="年度业绩目标完成占比"
                placement="top"
                effect="dark"
            >
              <div style="display:flex;">
                <div style="width: 90%">
                  <el-progress
                      :text-inside="true"
                      striped
                      :percentage="getPercent(item.accomplishTargetNumber,item.basicTargetNumber) > 100 ? 100 :getPercent(item.accomplishTargetNumber,item.basicTargetNumber)"
                      :stroke-width="28"
                      status="exception"
                  >
                    <!-- <span>进度条当中显示文本</span>-->
                  </el-progress>
                </div>
                <div style="font-size: 14px;margin-left: 10px;font-weight: bold">
                  <!-- <span>进度条右侧文本</span>-->
                </div>
              </div>
            </el-tooltip>
            <!--            课销分摊剩余总 金额-->
            <el-divider></el-divider>
            <div style="font-size: 14px;margin: 10px 0">年度分摊剩余金额：
              <span style="font-weight: bold">{{ item.remainingMoney }}元</span>
            </div>
          </div>
        </div>

        <!--  skeleton骨架等待区-->
        <div v-if="skeletonKeXiao" class="items">
          <div v-for=" (item,index) in 5" :key="index" style="text-align: center" class="item">
            <el-skeleton :rows="0" animated/>
            <div style="text-align: left;margin: 10px 0">
              <el-skeleton-item variant="text" style="width: 40%;"/>
            </div>
            <el-skeleton-item variant="text" style="margin-right: 16px"/>
            <div style="display: flex;align-items: center;margin-top: 10px">
              <el-skeleton-item variant="text" style="margin-right: 16px"/>
              <el-skeleton-item variant="text" style="width: 30%"/>
            </div>
          </div>
        </div>

        <!-- 如果没有后续数据了-->
        <div v-if="noDataKeXiao === true">
          <el-empty style="margin-top: 150px" v-if="keXiaoTotal===0" description="未查到数据噢!！"/>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {loadYearTotalTargetKeXiao, loadYearTotalTargetYeJi} from "@/api/performanceTarget";

export default {
  name: "YearTotalTarget",
  data() {
    return {
      //日期选择
      choiceDate: new Date(),
      //业绩相关
      tableYeJiData: [],
      skeletonYeJi: true,
      noDataYeJi: false,
      yeJiTotal: 0,
      //课消相关
      tableKeXiaoData: [],
      skeletonKeXiao: true,
      noDataKeXiao: false,
      keXiaoTotal: 0,
      //参数
      query: {
        year: null,
      }
    }
  },
  methods: {
    //表格触底 继续加载
    async onReachBottom() {
      if (this.tableKeXiaoData.length >= 25) {
        console.log("表格触底了并且len>25...")
        //继续加载数据
        //await this.getData();
      }
      console.log("表格触底了...")
    },
    //查询按钮时间 --加载数据前
    async selectData() {
      //骨架初始化
      this.skeletonYeJi = true;
      this.skeletonKeXiao = true;
      //无数据 暂时设置为false
      this.noDataYeJi = false;
      this.noDataKeXiao = false;
      //清空 两个数据集
      this.tableYeJiData = [];
      this.tableKeXiaoData = [];
      await this.getData();
    },
    //获取所选日期中的 年份
    getYearAndMonth(val) {
      let date = new Date(val)
      let month = date.getMonth() + 1;
      month = month < 10 ? ('0' + month) : month;
      return [date.getFullYear(), month]
    },
    /**
     * 计算num占total的百分百多少
     * @param num 实际完成
     * @param total 总目标
     * @returns {number}
     */
    getPercent(num, total) {
      if ((num == null || total == null) || (num === 0 || total === 0)) {
        return 0;
      }
      return (Math.round(num / total * 10000) / 100.00);// 小数点后两位百分比
    },

    /**
     * 获取列表信息
     */
    getData: async function () {
      console.log("CJL TES  ---getData run");
      if (this.choiceDate != null) {
        let yearMonthDateList = this.getYearAndMonth(this.choiceDate);
        this.query.year = yearMonthDateList[0];
      } else {
        this.query.year = null;
      }

      console.log("CJL TES  ---begin selectList run1");
      await loadYearTotalTargetYeJi(this.query).then(res => {
        //骨架消失
        this.skeletonYeJi = false;

        console.log("开始返回1")
        //表格数据加入
        this.tableYeJiData.push(...res.data);
        console.log("开始总条数设置1")
        //总数设置
        this.yeJiTotal = res.data.length;
        console.log("判定 是否 无数据1：条数" + this.yeJiTotal)
        if (this.tableYeJiData.length === this.yeJiTotal) {
          this.noDataYeJi = true;
        }
      })

      console.log("CJL TES  ---begin selectList run2");
      await loadYearTotalTargetKeXiao(this.query).then(res => {
        //骨架消失
        this.skeletonKeXiao = false;

        console.log("开始返回2")
        //表格数据加入
        this.tableKeXiaoData.push(...res.data);
        console.log("开始总条数设置2")
        //总数设置
        this.keXiaoTotal = res.data.length;

        console.log("判定 是否 无数据2：条数" + this.keXiaoTotal)
        if (this.tableKeXiaoData.length === this.keXiaoTotal) {
          this.noDataKeXiao = true;
        }
      })
    },
  },
  created() {
    console.log("CJL TES  ---created run");
    this.selectData();
  },
}
</script>

<style scoped>
.margin10 {
  margin: 10px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.item {
  margin-bottom: 20px;
  margin-top: 10px;
  width: 250px;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0px 1px 10px 1px #f5f5f5;
}

.item:hover {
  box-shadow: 0px 1px 20px 1px #e2e2e2;
}

.infinite-list {
  margin-top: 20px;
  height: 700px;
  overflow: auto
}
</style>
